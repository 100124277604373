import React, { useState } from 'react';
import './testPage.less';
import { Layout } from '../components/Layout/Layout';
import { graphql, PageProps } from 'gatsby';
import { TestPageQuestions } from '../types/index';
import Test from '../components/Test/Test';
import TestDone from '../components/TestDone/TestDone';
import TestResult from '../components/TestResult/TestResult';
import TestHeader from '../components/TestHeader/TestHeader';
import { Breadcrumb, Divider } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ROUTES } from '../constants/routes';

type TestPageProps = {
  strapiLessonTest: {
    strapi_id: number;
    description: string;
    questions: TestPageQuestions;
    index: number;
    answerArr: {
      strapi_id: number;
    }[];
    lesson: {
      lessonNumber: number;
      course: {
        slug: string;
        name: string;
      };
    };
  };
};

export default function TestPage({
  data: { strapiLessonTest },
}: PageProps<TestPageProps>) {
  const lessonPath = strapiLessonTest?.lesson;
  const courseURL = `${ROUTES.COURSE}/${lessonPath?.course.slug}`;
  const courseName = lessonPath?.course.name;
  const lessonUrl = `${ROUTES.COURSE}/${lessonPath?.course.slug}/lekcja${lessonPath?.lessonNumber}`;
  const lessonName = `Lekcja ${lessonPath?.lessonNumber}`;
  const testURL = `${ROUTES.COURSE}/${lessonPath?.course.slug}/lekcja${lessonPath?.lessonNumber}/test`;
  const [question, setQuestion] = useState(1);
  const questionArr: TestPageQuestions = strapiLessonTest?.questions;
  const initialCheckedState = questionArr.map(singleQuestion =>
    singleQuestion?.options.map(option => ({
      id: option.strapi_id,
      value: false,
    }))
  );
  const [checkedState, setCheckedState] = useState(initialCheckedState);
  const [answerArr, setAnswerArr] = useState<{ strapi_id: number }[][]>([]);
  const [answer, setAnswer] = useState([]);
  const correctAnswersArr = questionArr.map(correctAnswer =>
    correctAnswer.correctAnswers.map(id => id)
  );
  const [correctAnswer, setCorrectAnswer] = useState(correctAnswersArr);
  const [showPage, setShowPage] = useState('Test');

  const nextQuestion = () => {
    if (question < questionArr.length) {
      setQuestion(question + 1);
    } else {
      setShowPage('TestDone');
    }
    setAnswerArr(currentAnswer =>
      currentAnswer[question - 1]
        ? currentAnswer.map((element, questionIndex) =>
            questionIndex === question - 1 ? answer : element
          )
        : [...answerArr, answer]
    );
    setAnswer(answerArr[question] ?? []);
  };

  const previousQuestion = () => {
    if (question === 1) return;
    setQuestion(question - 1);
    setAnswer(answerArr[question - 2] ?? []);
  };

  const handleCheckbox = (e: any, position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === question - 1
        ? item.map((checkboxData, index) =>
            index === position
              ? { id: checkboxData.id, value: !checkboxData.value }
              : checkboxData
          )
        : item
    );
    setCheckedState(updatedCheckedState);
    const isChecked = e.target.checked;
    const checkboxValue = e.target.value;

    const isFound = answer.some((element: { strapi_id: number }) => {
      return element.strapi_id === checkboxValue.strapi_id;
    });

    if (isChecked) {
      if (isFound) return;
      answer.push(checkboxValue);
    } else {
      const index = answer
        .map((item: { strapi_id: number }) => item.strapi_id)
        .indexOf(checkboxValue.strapi_id);
      if (
        answer
          .map((item: { strapi_id: number }) => item.strapi_id)
          .includes(checkboxValue.strapi_id)
      ) {
        answer.splice(index, 1);
      }
    }
    setAnswer(answer);
  };
  let points = 0;
  const correctQuestions = [];

  const choosenAnswers = answerArr.map(singleAnswer =>
    singleAnswer.map(elementId => elementId.strapi_id)
  );
  const correctAnswerArr = correctAnswer.map(singleCorrectAnswer =>
    singleCorrectAnswer.map(elementId => elementId.strapi_id)
  );

  function compareNumbers(a: number, b: number) {
    return a - b;
  }

  const checkingResult = () => {
    for (let i = 0; i <= questionArr.length - 1; i++) {
      choosenAnswers[i]?.sort(compareNumbers);
      correctAnswerArr[i]?.sort(compareNumbers);
      if (choosenAnswers[i]?.length === correctAnswerArr[i].length) {
        if (
          JSON.stringify(choosenAnswers[i]) ==
          JSON.stringify(correctAnswerArr[i])
        ) {
          points = points + 1;
          correctQuestions.push(questionArr[i].strapi_id);
          continue;
        } else if (
          JSON.stringify(answerArr[i]) !== JSON.stringify(correctAnswer[i])
        ) {
          continue;
        }
      }
    }
  };
  checkingResult();

  const handleTestResult = () => {
    setShowPage('TestResult');
  };

  const Switch = (param: string) => {
    switch (param) {
      case 'Test':
        return (
          <Test
            question={question}
            questionArr={questionArr}
            handlePrevious={previousQuestion}
            handleNext={nextQuestion}
            handleCheckbox={handleCheckbox}
            strapiLessonTest={strapiLessonTest}
            checkedState={checkedState}
          />
        );
      case 'TestDone':
        return (
          <TestDone
            questionArr={questionArr}
            handleTestResult={handleTestResult}
            restartUrl={testURL}
            answerArr={answerArr}
            correctAnswer={correctAnswer}
            points={points}
          />
        );
      case 'TestResult':
        return (
          <TestResult
            handleTestResult={handleTestResult}
            answerArr={answerArr}
            correctAnswer={correctAnswer}
            correctQuestions={correctQuestions}
            choosenAnswers={choosenAnswers}
            strapiLessonTest={strapiLessonTest}
            correctAnswerArr={correctAnswerArr}
            questionArr={questionArr}
            checkedState={checkedState}
            restartUrl={testURL}
            returnToLesson={lessonUrl}
            question={question}
            points={points}
          />
        );

      default:
        <Test
          question={question}
          questionArr={questionArr}
          handlePrevious={previousQuestion}
          handleNext={nextQuestion}
          handleCheckbox={handleCheckbox}
          strapiLessonTest={strapiLessonTest}
          checkedState={checkedState}
        />;
    }
  };
  return (
    <Layout>
      <section className="test-page-wrapper section-content-container">
        <Breadcrumb separator={<ArrowRightOutlined />}>
          <Breadcrumb.Item href={ROUTES.HOME}>Strona główna</Breadcrumb.Item>
          <Breadcrumb.Item href={ROUTES.COURSE}>Kurs</Breadcrumb.Item>
          <Breadcrumb.Item href={courseURL}>{courseName}</Breadcrumb.Item>
          <Breadcrumb.Item href={lessonUrl}>{lessonName}</Breadcrumb.Item>
          <Breadcrumb.Item href={testURL}>Test</Breadcrumb.Item>
        </Breadcrumb>
        <Divider></Divider>
        <div className="test-page-title">
          <TestHeader />
        </div>
      </section>
      <div className="test-wrapper">{Switch(showPage)}</div>
    </Layout>
  );
}

export const query = graphql`
  query ($strapi_id: Int) {
    strapiLessonTest(strapi_id: { eq: $strapi_id }) {
      strapi_id
      description
      lesson {
        course {
          slug
          name
        }
        lessonNumber
      }
      questions {
        strapi_id
        description
        options {
          description
          strapi_id
        }
        correctAnswers {
          strapi_id
        }
      }
    }
  }
`;
