import React, { useEffect, useState } from 'react';
import './TestDone.less';
import { Typography, Button } from 'antd';
import { Link } from 'gatsby';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import TestButton from '../TestButton/TestButton';
const { Paragraph, Title } = Typography;

type TestDoneProps = {
  points: number;
  questionArr: [
    {
      description: string;
      strapi_id: number;
    }
  ];
  handleTestResult: () => void;
  restartUrl: string;
  return: string;
  backToLesson: string;
  reset: string;
  restartTest: string;
};

const TestDone = (data: TestDoneProps) => {
  const [showResult, setShowResult] = useState<'failed' | 'success' | string>(
    ''
  );

  const halfOfQuestions = data.questionArr.length / 2;

  useEffect(() => {
    if (data.points <= halfOfQuestions) {
      setShowResult('failed');
    } else {
      setShowResult('success');
    }
  });
  const Switch = (param: string) => {
    switch (param) {
      case 'success':
        return (
          <>
            <CheckCircleFilled className="success" />
            <Title level={3} className="success">
              Zaliczone!
            </Title>
            <Paragraph className="sub-title">
              Gratulacje! Twój wynik to:
            </Paragraph>
            <div className="test-done-points">
              <Paragraph className="success">{data.points}</Paragraph>
              <Paragraph>/{data.questionArr.length}</Paragraph>
            </div>
          </>
        );
      case 'failed':
        return (
          <>
            <CloseCircleFilled className="failed" />
            <Title level={3} className="failed">
              Spróbuj jeszcze raz!
            </Title>
            <Paragraph className="sub-title">
              Niestety trochę Ci zabrakło. Twój wynik to:
            </Paragraph>
            <div className="test-done-points">
              <Paragraph className="failed">{data.points}</Paragraph>
              <Paragraph>/{data.questionArr.length}</Paragraph>
            </div>
          </>
        );
      default:
        <>
          <CheckCircleFilled className="success" />
          <Title level={3} className="success">
            Zaliczone!
          </Title>
          <Paragraph className="sub-title">
            Gratulacje! Twój wynik to:
          </Paragraph>
          <div className="test-done-points">
            <Paragraph className="success">{data.points}</Paragraph>
            <Paragraph>/{data.questionArr.length}</Paragraph>
          </div>
        </>;
    }
  };
  return (
    <div className="test-done-wrapper">
      {Switch(showResult)}
      <div className="test-done-buttons">
        <Link className="link" to={data.return ?? ''}>
          <TestButton
            type="primary"
            className="test-done-button"
            onClick={data.handleTestResult}
          >
            {data.backToLesson ?? 'Zobacz odpowiedzi'}
          </TestButton>
        </Link>
        <Button
          type="primary"
          className="test-done-button-link"
          onClick={() => location.reload()}
        >
          {data.restartTest ?? 'Spróbuj jeszcze raz'}
        </Button>
      </div>
    </div>
  );
};

export default TestDone;
