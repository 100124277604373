import React from 'react';
import { Button } from 'antd';

type TestButtonProp = {
  type: any;
  className: string;
  onClick: () => void;
  children: string;
};

const TestButton = (prop: TestButtonProp) => {
  const propType = prop.type;
  const propOnClick = prop.onClick;
  const className = prop.className;
  return (
    <Button type={propType} onClick={propOnClick} className={className}>
      {prop.children}
    </Button>
  );
};

export default TestButton;
