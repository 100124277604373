import React, { useState, useMemo } from 'react';
import './Test.less';
import { Typography, Checkbox, Progress } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TestButton from '../TestButton/TestButton';
const { Paragraph, Title } = Typography;

type TestProps = {
  question: number;
  questionArr: {
    description: string;
    strapi_id: number;
  }[];
  strapiLessonTest: {
    strapi_id: number;
    description: string;
    questions: {
      strapi_id: number;
      description: string;
      options: {
        description: string;
        strapi_id: number;
      }[];
      correctAnswers: {
        description: string;
        strapi_id: number;
      };
    }[];
  };
  handlePrevious: () => void;
  handleNext: () => void;
  handleCheckbox: (e: CheckboxChangeEvent, index: number) => void;
  checkedState: {
    value: boolean;
    id: number;
  }[][];
};

const Test = (params: TestProps) => {
  const question =
    params?.strapiLessonTest?.questions[params.question - 1]?.options || [];

  const memorizedPercentageOnTheBar = useMemo(() => {
    const maxBar = 100;
    const percentageForQuestion = maxBar / params.questionArr.length;
    const percentageOnTheBar = percentageForQuestion * params.question;
    return percentageOnTheBar;
  }, [params.question]);
  return (
    <>
      <div className="test-question-wrapper section-content-container">
        <Title className="test-question-counter" level={4}>
          Pytanie {params.question} z {params.questionArr.length}
        </Title>
        <Progress
          className="progress-bar"
          percent={memorizedPercentageOnTheBar}
          format={percent => ''}
        />
        <Title className="test-question" level={4}>
          {params.questionArr[params.question - 1].description}
        </Title>
        <Paragraph className="test-description">
          (Możliwość wyboru kilku poprawnych odpowiedzi)
        </Paragraph>
        <div className="answer-wrapper">
          {question.map(
            (
              option: {
                strapi_id: number;
                description: string;
              },
              index: number
            ) => {
              return (
                <Checkbox
                  className="checkbox"
                  key={index}
                  value={{ strapi_id: option?.strapi_id }}
                  onChange={e => params.handleCheckbox(e, index)}
                  checked={
                    params.checkedState[params.question - 1][index].value
                  }
                >
                  {option?.description}
                </Checkbox>
              );
            }
          )}
        </div>
      </div>
      <div className="section-content-container">
        <div className="test-button-wrapper">
          <TestButton
            type="primary"
            className="test-button-previous"
            onClick={params.handlePrevious}
          >
            Poprzednie pytanie
          </TestButton>
          <TestButton
            type="primary"
            className="test-button-next"
            onClick={params.handleNext}
          >
            Następne pytanie
          </TestButton>
        </div>
      </div>
    </>
  );
};

export default Test;
