import React from 'react';
import './TestHeader.less';
import { Typography } from 'antd';
const { Title } = Typography;

const TestHeader = () => {
  return (
    <div className="test-header-wrapper">
      <Title level={3} className="test-main-header">
        Test sprawdzający wiedzę
      </Title>
      <Title level={4}>Lekcja 1: Lorem ipsum</Title>
    </div>
  );
};
export default TestHeader;
