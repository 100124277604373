import React from 'react';
import './TestResult.less';
import { Typography, Checkbox, Button } from 'antd';
import TestDone from '../TestDone/TestDone';
const { Title } = Typography;
import { CheckOutlined, CloseSquareFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { Link } from 'gatsby';

type TestResultProp = {
  strapiLessonTest: {
    questions: {
      strapi_id: number;
      description: string;
      options: {
        description: string;
        strapi_id: number;
      }[];
    }[];
  };
  correctAnswer: {
    strapi_id: number;
  }[][];
  questionArr: {
    strapi_id: number;
    description: string;
    options: {
      description: string;
      strapi_id: number;
    }[];
  }[];
  handleTestResult: () => void;
  restartUrl: string;
  answerArr: {}[];
  points: number;
  returnToLesson: string;
  checkedState: {
    value: boolean;
  }[][];
};
const TestResult = (data: TestResultProp) => {
  const answers = data.strapiLessonTest?.questions.map(options => {
    return {
      options: options?.options.map(singleOption => singleOption),
      strapi_id: options.strapi_id,
    };
  });

  const title = data.strapiLessonTest?.questions.map(
    (titles: { description: string }) => titles.description
  );
  const isInAcorrectAnswers = (
    index: number,
    singleAnswer: { strapi_id: number }
  ) => {
    const correctAnswerArr = data.correctAnswer[index];
    const isTrue = correctAnswerArr.some(
      (correctAnswer: { strapi_id: number }) =>
        correctAnswer.strapi_id == singleAnswer.strapi_id
    );
    if (isTrue) {
      return <CheckOutlined />;
    }
    if (isTrue) {
      return isTrue;
    }
  };
  const changeCheckbox = (
    index: number,
    singleAnswer: { strapi_id: number },
    singleAnswerIndex: number
  ) => {
    if (!isInAcorrectAnswers(index, singleAnswer)) {
      if (data.checkedState[index][singleAnswerIndex].value) {
        return <CloseSquareFilled className="checkbox-faill" />;
      }
    }
  };

  return (
    <>
      <TestDone
        questionArr={data.questionArr}
        handleTestResult={data.handleTestResult}
        restartUrl={data.restartUrl}
        answerArr={data.answerArr}
        correctAnswer={data.correctAnswer}
        points={data.points}
        backToLesson="Wróć do lekcji"
        restartTest="Spróbuj jeszcze raz"
        reset={data.restartUrl}
        return={data.returnToLesson}
      />
      <div className="test-result-questions-wrapper section-content-container">
        {answers.map((optionsArr, optionsArrIndex: number) => {
          console.log(data.questionArr);
          return (
            <div
              key={optionsArrIndex}
              className={classNames('wrapper', {
                'wrong-answer': !data.correctQuestions.includes(
                  optionsArr.strapi_id
                ),
              })}
            >
              <Title level={4} className="test-result-question-number">
                Pytanie {optionsArrIndex + 1}
              </Title>
              <Title level={4}> {title[optionsArrIndex]} </Title>
              {optionsArr.options.map((singleAnswer, singleAnswerIndex) => {
                return (
                  <div
                    key={singleAnswerIndex + 1}
                    className="test-result-questions"
                  >
                    <div
                      className={classNames('test-result-question', {
                        'test-result-questions-succes':
                          isInAcorrectAnswers(optionsArrIndex, singleAnswer) &&
                          data.checkedState[optionsArrIndex][singleAnswerIndex]
                            .value,
                        'test-result-questions-faill':
                          !isInAcorrectAnswers(optionsArrIndex, singleAnswer) &&
                          data.checkedState[optionsArrIndex][singleAnswerIndex]
                            .value,
                      })}
                    >
                      {changeCheckbox(
                        optionsArrIndex,
                        singleAnswer,
                        singleAnswerIndex
                      )}
                      <Checkbox
                        key={singleAnswerIndex}
                        checked={
                          data.checkedState[optionsArrIndex][singleAnswerIndex]
                            .value
                        }
                        value={singleAnswer.strapi_id}
                      >
                        {optionsArr.options[singleAnswerIndex].description}
                      </Checkbox>
                      {isInAcorrectAnswers(optionsArrIndex, singleAnswer)}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        <div className="test-result-bottom-buttons">
          <Link to={data.returnToLesson}>
            <Button type="primary" className="first-bottom-button">
              Wróć do lekcji
            </Button>
          </Link>
          <Link to={data.restartUrl}>
            <Button type="primary" className="second-bottom-button">
              Spróbuj jeszcze raz
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TestResult;
